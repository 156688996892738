<template>
	<div class="wrap" v-if="info">
		<div class="header flex-direction-column">
			<span> {{ info.title }} </span>
			<p>{{ info.create_time }}</p>
		</div>
		<div class="rich" style="display: inline-block" v-html="info.content"></div>
		<div class="from" v-if="info.source">（来源：{{ info.source }}）</div>
		<div class="enclosure_body" v-if="info.annex.length > 0">
			<span>附件：</span>
			<div class="enclosure">
				<div style="margin-bottom: 5px" @click="annexClick(item)" v-for="(item, index) in info.annex" :key="index">
					{{ item.name }}
				</div>
			</div>
		</div>
		<div class="article flex-direction-column">
			<span @click="choiceClick(info.last.id)" v-if="info.last">上一篇：{{ info.last.title }}</span>
			<span @click="choiceClick(info.next.id)" v-if="info.next">下一篇：{{ info.next.title }}</span>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {downloadFile} from '@/utils/tools'
import {postDetail} from '@/service/api/index'

export default {
	name: 'nedetail',
	data() {
		return {
			info: null
		}
	},

	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())

		if (this.$route.params.info) {
			let info = this.$route.params.info
			if (this.$route.params.home) {
				sessionStorage.setItem('navbarId', this.$route.params.home.id)
			} else {
				sessionStorage.setItem('navbarId', info.id)
			}

			sessionStorage.setItem('navbarStatus', info.status)
			this.postDetail(info.id, info.status)
		}

		EventBus.$on('aside', (res) => {
			sessionStorage.setItem('navbarId', res.id)
			sessionStorage.setItem('navbarStatus', res.status)
			this.postDetail(res.id, res.status)
		})
	},
	destroyed() {
		EventBus.$off('aside')
		window.removeEventListener('beforeunload', this.beforeunloadFn())
	},

	methods: {
		postDetail(id, status) {
			postDetail({id, status}).then((res) => {
				this.info = res.msg
			})
		},
		beforeunloadFn() {
			if (!this.$route.params.info && this.$route.path == '/pudetail') {
				let navbarStatus = sessionStorage.getItem('navbarStatus')
				let navbarId = sessionStorage.getItem('navbarId')
				this.postDetail(navbarId, navbarStatus)
			}
		},
		choiceClick(id) {
			this.postDetail(id)
		},
		annexClick(item) {
			downloadFile(item.url, item.name)
		}
	}
}
</script>

<style lang="less" scoped>
.from {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 20px;
	font-size: 14px;
	font-weight: bold;
	line-height: 25px;
	color: #333;
	margin-top: 41px;
}

.header {
	align-items: center;
	justify-content: center;

	span {
		line-height: 40px;
		font-size: 28px;
		font-weight: bold;
	}

	p {
		color: #777777;
		height: 22px;
		font-size: 16px;
		margin-top: 10px;
	}
}

.wrap {
	padding: 30px 44px 40px 44px;
	background: #fff;

	.enclosure_body {
		margin-top: 20px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		.enclosure {
			font-size: 16px;
			color: #4786e9;
			padding-bottom: 36px;
			border-bottom: 1px solid #eee;
			cursor: pointer;

			span {
				margin: 0;
				//margin-right: 10px;
				color: #333333;
			}
		}
	}

	.article {
		margin-top: 37px;
		cursor: pointer;

		span {
			height: 25px;
			font-size: 14px;
			line-height: 25px;
			color: #333333;
			margin-bottom: 13px;

			&:hover {
				color: #999999;
			}
		}
	}

}

/deep/ img {
	//width: 100%;
	display: inline-block !important;
}

/deep/ span {
	//width: 100%;
	display: inline-block !important;
}
</style>
